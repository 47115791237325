


import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import outofbox from 'assets/video/outofbox.mp4'

export default function OutOfBox() {
    return (
        <>
<br/>
            <Typography variant="body1" color="initial">
                Few historians would disagree that the Second World War (1939-1945) has left an indelible mark on nearly every nation in the globe, but the presiding narrative of this conflict has been primarily Eurocentric. The part played by colonial armies like the Indian Army was, to a great extent, very significant in securing the Allied victory over the Axis Powers, most notably in the Middle East, African and South-East Asian theatres of the war. The 4th, 5th, 8th, and 10th Indian Divisions fought against Germany and Italy in the North African Campaign, sending over 2.5 million men overseas, which is considered the largest volunteer force in history. The economic, military, and industrial support extended by India was invaluable, in terms of its vast armed forces, massive scale of armaments and supply of food, timber and steel. In the Western Desert Campaign (1940-1943), the Indian Army played a critical role in fighting the Afrika Korps under the ‘Desert Fox’ Erwin Rommel. Join us on 'Out of the Box' Interviews as we deep-dive into some of the questions surrounding the role of the Indian Army in determining the military outcome of the Western Desert Campaign and by extension, the Second World War with World War II veteran Lt. General SN 'Tindi' Sharma. This topic is of personal significance to me as my maternal great grandfather (Bade Dada) also fought in the Western Desert Campaign. Then captain and later Lt. General S. N. Mubayi faced the ‘Desert Fox’ Erwin Rommel in the Second Battle of El Alamein as part of General Montgomery’s 8th Army. Tales of his bravery, determination, and resilience on the battlefield have been passed down through the generations to serve as an inspiration for us all.
            </Typography>

            <br/>
<br/>

            <video width="100%" height="auto" controls>
                  <source src={outofbox} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
        </>
    )
}