import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import Navbar from 'Layout/Navbar'
import canvas from 'assets/walk/canvas.jpeg'
import melodies from 'assets/walk/melodies.jpeg'
import PDFView from './PDFView'
import CertPDF from './CertPDF'
import KavitaPDF from './KavitaPDF'

import Slides from 'Layout/Walk'

const StyledContainer = styled(Grid)(({ theme }) => ({
  position: 'relative',
  '&:before': {
    position: 'absolute',
    top: '0',
    right: '0',
    left: '0',
    bottom: '0',
    background: theme.palette.primary.main,
    // backgroundImage: `linear-gradient(45deg, #654ea3, #eaafc8)`,
    transform: 'skewY(-11deg)',
    content: `""`
  }
}));

export default function BasicGrid() {
  return (
    <Box sx={{ flexGrow: 1, background: 'white', minHeight: '100vh' }}>
      <Navbar />
      <Box sx={{
        overflowY: 'scroll',
        position: 'absolute',
        height: '100%',
        width: '-webkit-fill-available'
      }}>


        <Container sx={{ my: 5 }} maxWidth="md" data-aos="fade-up">

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h2" gutterBottom >'Walk the Talk' Initiatives </Typography>
              <Divider />
            </Grid>
            <Grid item xs={12}>

              {/* <Typography variant="h6" gutterBottom >ABOUT US</Typography> */}
              <Typography variant="body1">

                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp;
                I am the founder and leader of ‘Walk the Talk’, a social initiative that is close to my heart,
                as it amalgamates my passion in art and music with a drive to give back to society.
                I began what was to become a transformative journey conducting structured and engaging art modules for
                underprivileged students of grades 3, 4, and 5 at the SDMC Primary School in Amar Colony,
                which would culminate into a fund-raising event. Before I knew it, I had fallen in love with this place of
                laughter and learning, where I was reminded of the simple joys of life and more importantly that I have much to be
                grateful for.
                However, the onset of the pandemic created unprecedented chaos, causing me to review my plans for the initiative.
                In the interim period, I created a team of four individuals, the main portfolios being – a Chief Editor (Giya Sood), a Design Head
                (Maya Ghosh), a Media Head (Nitika Khungar) and a Head of Information Technology (Rohan Kapur). Together, we successfully organised
                ‘Melodies for Hope’ in November 2020.
                A virtual concert cum competition which brought together over 30 students of grades 6-10 in musical expression, its goal was to
                support the digital learning of the students of the SDMC Primary Schools, who were adversely affected after the COVID-19 pandemic.
                We were able to raise around INR 2 lakh for the schools and had an audience of close to 700 people. In addition to this,
                I have organised a book donation to the school of over 500 books and board games to facilitate a greater horizon of learning outside
                the classroom and hope to host reading sessions and seminars with the children once the new term begins.

                
                <br />&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp;
                Art is something that isn’t limited to a canvas but a concept that transcends time and space; something that is ‘Beyond Boundaries’.
                Through various mediums, including paintings, photographs, digital art, and virtual reality installations, we sought to encapsulate
                the true essence of creative spirit in our recent fundraising exhibition ‘Canvas for Change’ (May 2022). Through this art event,
                we aimed to widen the scope of extracurricular activities the students of the SDMC Primary Schools have access to. We, as students of
                the Shri Ram School, Moulsari, have the privilege of being able to engage in numerous such activities outside the classroom and
                understand just how valuable they are to our overall growth.
                The artwork in the exhibition was symbolic of the journey of several budding artists cut across different ages, including the students
                of the SDMC Primary Schools, who came together to create an experience like no other. To me, art has always been a journey – not a destination in itself, but a path that opens up new avenues of exploration. The exhibition indeed became a true representation of the worthy cause at hand: the importance of broadening horizons through extracurricular activities.
                We received around 85 multi-media works, with over 100 visitors on the day of the event and 77 of the works being sold out in 7 hours.
                This eclectic experience raised over INR 1.5 lakh in support of the SDMC Primary Schools, proving to be an enriching endeavour for all parties involved.






                {/* &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp;
                My name is Yuvaan M. Bery, and I am the founder and leader of ‘Walk The Talk’, a social initiative that is close to my heart, as it amalgamates my passion in art and music with a drive to give back to society. I began what was to become a transformative journey conducting structured and engaging art modules for underprivileged students of grade 3,4 and 5 at the SDMC Primary School in Amar Colony, which would culminate into a fund-raising event. Before I knew it, I had fallen in love with this place of laughter and learning; where I was reminded of the simple joys of life and more importantly that I have much to be grateful for.

                <br />&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp;
                However, the onset of the pandemic created unprecedented chaos, causing me to review my plans for the initiative. In the interim period, I created a team of four individuals, the main portfolios being – a Chief Editor (Giya Sood), a Design Head (Maya Ghosh), a Media Head (Nitika Khungar) and a Head of Information Technology (Rohan Kapur). Together, we successfully organised Melodies for Hope in November 2020. A virtual concert cum competition which brought together over 30 students of grades 6-10 in musical expression, its goal was to support the digital learning of the students of the SDMC Primary Schools, who were adversely affected after the COVID-19 pandemic. We were able to raise around INR 2 lakh for the schools and had an audience of close to 700 people. In addition to this, I have organised a book donation to the school of over 300 books in order to facilitate a greater horizon of learning outside the classroom and hope to host reading sessions and seminars with the children once the new term begins.
                <br />&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp;
                Art is something that isn’t limited to a canvas but a concept that transcends time and space; something that is beyond boundaries. Through various mediums, including paintings, photographs, digital art and virtual reality installations, we sought to encapsulate the true essence of creative spirit in our recent fundraising exhibition ‘Canvas for Change’ (May 2022). Through this art event, we aimed to widen the scope of extracurricular activities the students of the SDMC Primary Schools have access to. We, as students of the Shri Ram School, Moulsari, have the privilege of being able to engage in numerous such activities outside the classroom and understand just how valuable they are to our overall growth.

                <br />&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp;
                The artwork in the exhibition was symbolic of the journey of several budding artists cut across different ages, including the students of the SDMC Primary Schools, who have come together to create an experience like no other. To me, art has always been a journey – not a destination in itself, but a path that opens up new avenues of exploration. The exhibition indeed became a true representation of the worthy cause at hand: the importance of broadening horizons through extracurricular activities.
                <br />&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp;
                We received around 85 multi-media works (paintings, digital art, photographs and even a virtual reality installation) for the same, with over 100 visitors on the day of the event and 77 of the works being sold out in 7 hours. This eclectic experience raised over INR 1.5 lakh in support of the SDMC Primary Schools, proving to be an enriching endeavour for all parties involved.
             
              */}


              </Typography>

              <Divider sx={{ py: 2 }} />
            </Grid>

            <Grid item container xs={12} spacing={2} justifyContent="center">
              <Grid item xs={12}>
                <Typography variant="h3" gutterBottom sx={{ my: 5 }} >Melodies for Hope</Typography>
              </Grid>
              <Grid item xs={12} lg={6}>
                <iframe width="100%" height="auto" src="https://www.youtube.com/embed/KxR8HoJ4fCk"></iframe></Grid>
              <Grid item xs={12} lg={6}>
                <iframe width="100%" height="auto" src="https://www.youtube.com/embed/4sD9r6JJCfo"></iframe>
              </Grid>

              <Grid item xs={7}>

                <Divider sx={{ py: 2 }} />

                <img src={melodies} alt="canvas for change invitation" height="auto" width="100%" />

              </Grid>


              <Grid item xs={12}>

                <Divider sx={{ py: 2 }} />

                <CertPDF />

                <Divider sx={{ py: 2 }} />
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h3" gutterBottom sx={{ my: 5 }} >Canvas for Change</Typography>
            </Grid>
            <Grid item xs={6}><PDFView /></Grid>
            <Grid item xs={6}>
              <img src={canvas} alt="canvas for change invitation" height="auto" width="100%" />
            </Grid>

          </Grid>

        </Container>



        <Container sx={{ mt: 20, pt: 5, color: 'white', mb: 10 }}  >
          <StyledContainer container spacing={2} >
            <Grid item xs={12} container
              spacing={2}
              sx={{
                p: 2,
                maxWidth: '50em',
                margin: '0 auto',
                position: 'relative',
                textAlign: 'center',
                color: 'white'
              }}>
              <Grid item xs={12}><Typography variant="h3" gutterBottom sx={{ my: 5 }}  >Media Coverage</Typography></Grid>
              <Grid item xs={12} sx={{ pb: '6rem' }}> <KavitaPDF /></Grid>


            </Grid>

            <Grid item xs={12} sx={{ display: { xs: 'blok', md: 'none' } }}>
              <Slides />
            </Grid>
          </StyledContainer>


        </Container>
      </Box>
    </Box>
  );
}
