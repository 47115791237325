import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';

import guide from 'assets/historian/guide.jpg'
import { Typography } from '@mui/material';

import { motion } from "framer-motion";
import IconButton from '@mui/material/IconButton';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';

import revisionGuide from 'assets/historian/revisionGuide.pdf';

export default function SimpleContainer() {
    return (
        <React.Fragment>
            <CssBaseline />
            <Container maxWidth
                sx={{
                    backgroundImage: `url(${guide})`,
                    minHeight: '60vh',
                    backgroundSize: 'cover',
                    display: 'flex',
                    alignItems: 'center',
                    color: 'white',
                    justifyContent: 'center'
                }}
            >
                <Typography variant="h5" sx={{ cursor: 'pointer', color: 'white' }}
                    whileHover={{ scale: 1.2 }}
                    onClick={() => { window.open(revisionGuide) }}
                    component={motion.h5}>
                    <IconButton sx={{ color: 'white' }}><DownloadOutlinedIcon /></IconButton>
                    Download IB Revision Guide

                </Typography>
            </Container>
        </React.Fragment>
    );
}
