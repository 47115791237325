import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { motion } from "framer-motion";
import CardMedia from '@mui/material/CardMedia';
import aesthetic from 'assets/paint.jpg';

import { itemBox } from 'Animation'
import { StyledCard, StyledContent } from './StyledCard'
import Stack from '@mui/material/Stack';




export default function OutlinedCard() {

    const StyledButton = ({ url, text }) => {

        return <Button size="small"
        onClick={()=>{window.open(url)}}
            sx={{ letterSpacing: '2px', textAlign:'left' }}
            whileHover={{ scale: 1.2 }}
            variants={itemBox}
            component={motion.button}>
            {text}
        </Button>
    }

    return (
        <Box>
            <StyledCard>
                <CardMedia
                    component="img"
                    height="140"
                    image={aesthetic}
                    alt="aesthetic"
                />
                <StyledContent>
                    <Typography sx={{ fontSize: 20,  fontFamily: 'AbrilFatface' }} color="text.secondary" gutterBottom variants={itemBox} component={motion.div}>
                        Aesthete
                    </Typography>

                    <Stack direction="column"
                        justifyContent="center"
                        alignItems="flex-start"
                        spacing={2}>
                        <StyledButton text="The Aesthete 1" url='https://yuvaanblogcom.wordpress.com/2021/10/10/the-aesthete/'/>
                        <StyledButton text="The Aesthete 2" url="https://yuvaanblogcom.wordpress.com/2021/11/15/the-aesthete-2/" />
                        <StyledButton text="The Aesthete 3" url="https://yuvaanblogcom.wordpress.com/2022/01/06/the-aesthete-new-year-edition/" />
                        <StyledButton text="The Aesthete 4" url="https://yuvaanblogcom.wordpress.com/2022/03/19/the-aesthete-3/" />
                        <StyledButton text="The Aesthete 5" url="https://yuvaanblogcom.wordpress.com/2022/04/18/the-aesthete-4/" />
                    </Stack>



                </StyledContent>
            </StyledCard>
        </Box>
    );
}
