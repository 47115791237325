import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export default function Types() {
  return (
    <Box sx={{ width: '100%', color:'white',textAlign:'center' }}  data-aos="zoom-in">

      <Typography variant="h6" gutterBottom sx={{letterSpacing:'2px',fontWeight:200}}  >
        Leader &nbsp; | &nbsp;  Changemaker &nbsp;  | &nbsp;  Historian
      </Typography>
      <Typography variant="h1" gutterBottom  >
        Yuvaan M. Bery
      </Typography>


{/* <Typography variant="h6" display="block" gutterBottom sx={{color:'white'}}  >
        <i>"Not all those who wander are lost"</i> <br/> - J. R. R Tolkien
      </Typography> */}
    </Box>
  );
}
