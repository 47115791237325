import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import { Link } from 'react-router-dom'
import { styled } from '@mui/material/styles';

import SmMenu from './SmMenu'


const StyledButton = styled(Button)(({ theme }) => ({
  color: theme.palette.text.primary,
  letterSpacing: '.05rem',
  transitionDuration: '0.3s',
  transitionProperty: 'transform',
  transform: 'translateZ(0)',
  boxShadow: '0 0 1px rgba(0, 0, 0, 0)',
  // fontSize:'1rem',
  fontWeight: '700',
  // maringRight:'4px',
  // fontFamily:'Norican',
  '&:before': {
    pointerEvents: 'none',
    position: 'absolute',
    zIndex: '-1',
    content: `""`,
    top: '100%',
    left: '5%',
    height: '10px',
    width: '90%',
    opacity: '0',
    background: 'radial-gradient(ellipse at center, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0) 80%)',
    transitionDuration: '0.3s',
    transitionProperty: 'transform opacity',
  },
  '&:hover': {
    transform: 'translateY(-5px)',
    color: theme.palette.primary.main,
    '&:before': {
      opacity: '1',
      transform: 'translateY(5px)'
    }
  }
}));
const pages = [
  {
    title: 'Home',
    url: '/'
  },
  {
    title: 'About Me',
    url: '/about-me'
  },
  {
    title: '\'Walk the talk\' initiatives',
    url: '/walk-the-talk-initiatives'
  },
  {
    title: 'Historian\'s Corner',
    url: '/historians-corner'
  },
  {
    title: 'Awards & Recognitions',
    url: '/awards'
  },
  {
    title: 'Résumé',
    url: '/resume'
  },
];

const ResponsiveAppBar = () => {


  return (
    <AppBar position="static" sx={{ background: '#F1F3F6', borderRadius: 0 }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Typography
            variant="subtitle2"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
          </Typography>


          <Box sx={{ flexGrow: 1 }}></Box>
          <Box sx={{ display: { xs: 'block', md: 'none' } }}>
            <SmMenu>
              <Box sx={{
                display: { xs: 'flex', md: 'flex' },
                alignItems: { xs: 'center', md: 'auto' },
                flexDirection: { xs: 'column', md: 'row' },
                justifyContent: { xs: 'center', md: 'center' },
                height: { xs: '100%', lg: 'auto' },



              }}>
                {pages.map((page) => (
                  <Link to={page.url} style={{ color: 'inherit', textDecoration: 'none' }}>
                    <StyledButton
                      key={page.title}
                    // sx={{ my: 2,mx:.5, display: 'block' }}
                    >
                      {page.title}
                    </StyledButton>
                  </Link>
                ))}
              </Box>
            </SmMenu>

          </Box>
          <Box sx={{ display: { xs: 'none', md: 'block' } }}>
            <Box sx={{
              display: { xs: 'flex', md: 'flex' },
              alignItems: { xs: 'center', md: 'auto' },
              flexDirection: { xs: 'column', md: 'row' },
              justifyContent: { xs: 'center', md: 'center' },
              height: { xs: '100%', lg: 'auto' },



            }}>
              {pages.map((page) => (
                <Link to={page.url} style={{ color: 'inherit', textDecoration: 'none' }}>
                  <StyledButton
                    key={page.title}
                  // sx={{ my: 2,mx:.5, display: 'block' }}
                  >
                    {page.title}
                  </StyledButton>
                </Link>
              ))}
            </Box>
          </Box>




        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default ResponsiveAppBar;
