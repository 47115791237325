import * as React from 'react';
import Timeline from '@mui/lab/Timeline';
import { styled } from '@mui/material/styles';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import Typography from '@mui/material/Typography';

const StyledTimelineItem = styled(TimelineItem)(({ theme }) => ({
    '& .MuiTimelineDot-root':{
        background:'transparent',
        borderColor:theme.palette.text.secondary,
        borderWidth:'1px',
        padding:'7px',
        margin:0
    },
    '& .MuiTimelineContent-root':{
        paddingTop:0,
        paddingBottom:0
    }
  }));

export default function OppositeContentTimeline() {
  return (
    <React.Fragment>
      <Timeline
        sx={{
          [`& .${timelineItemClasses.root}:before`]: {
            flex: 0,
            padding: 0,
          },
        }}
      >
        {/* <StyledTimelineItem>
          <TimelineSeparator>
            <TimelineDot />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <Typography variant="subtitle1">The Shri Ram School (May 2023)</Typography>
            <Typography variant="body2" gutterBottom>Moulsari,Gurugram,Haryana,India</Typography>
            <ul>
                <li>4 A* in the IGCSE School Examinations in Grade 9 (2019)</li>
                <li>9 A* and distinction in the IGSCE Board Examinations in Grade</li>
                <li>40/42 in the IB Examinations in Grade 11 (2021) </li>
            </ul>
            <br/>

          </TimelineContent>
        </StyledTimelineItem> */}

        {/* <StyledTimelineItem>
          <TimelineSeparator>
            <TimelineDot />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
          <Typography variant="subtitle1">Standardised Testing  (Dec 2021)</Typography>
            <Typography variant="body2" gutterBottom>ACT Score 34</Typography>
            <br/>
          </TimelineContent>
        </StyledTimelineItem> */}

        <StyledTimelineItem>
          <TimelineSeparator>
            <TimelineDot />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <Typography variant="subtitle1">Academic Awards (2016-2022)</Typography>
            <ul>
                <li>Only student in the batch to recieve the <b>Form Topper</b> Awards for 5 consecutive years</li>
                <li><b>Three-time</b> recipient of the <b>CAIE Star of the Year</b> for atleast 5 A* and 2 A in CAIE/IGCSE Examinations</li>
                <li><b>Overall Academic Excellence</b> achieved in the IGCSE Board Examinations</li>
                <li><b>Subject Excellence</b> Award achieved in Literature in English, Global Perspective, Chemistry and Foreign Language Spanish <b>(2019-20)</b></li>
                <li><b>Subject Excellence </b> Award achieved in all 9 subjects in the IGCSE Borad Examinations
                    <ol>
                        <li>History</li>
                        <li>Art & Design</li>
                        <li>Literature in English</li>
                        <li>First Language English</li>
                        <li>Global Perspectives</li>
                        <li>Foreign Language Spanish</li>
                        <li>Mathematics Extended</li>
                        <li>Chemistry</li>
                        <li>Physics</li>
                    </ol>
                </li>
                <li><b>School Topper</b> in ARt & Design in the IGCSE Board Examinations</li>
            </ul>
            <br/>

          </TimelineContent>
        </StyledTimelineItem>

<StyledTimelineItem>
  <TimelineSeparator>
    <TimelineDot />
    <TimelineConnector />
  </TimelineSeparator>
  <TimelineContent>
    <Typography variant="subtitle1">Other Awards (2016-2022)</Typography>
    <ul>
        <li>Four-time recipient of the <b>Triveni Award</b> for excellence in extracurricular activities</li>
        <li>Two-time recipient of the <b>Prayukti</b> Award for lateral thinking</li>
        <li>Recieved the <b>Innovators Award</b> for Project Barkhat</li>
        <li>Recieved the all India <b>Young Thinker's</b> Award for work donr in The new Normal Essay Competiotion (TNNEssay Competition - July 2020)</li>
        <li>Won <b>1<sup>st</sup> Prize</b> in the UN Social Conclave for our creative solution <b>Project Barkhat </b>
         for <b>Zero Hunger</b> (April 2021)</li>
    </ul>
    <br/>
  </TimelineContent>
</StyledTimelineItem>

{/* <StyledTimelineItem>
  <TimelineSeparator>
    <TimelineDot />
  </TimelineSeparator>
  <TimelineContent>
    <Typography variant="subtitle1">Other Courses, Online (2020-2022)</Typography>
    <ul>
        <li><b>Ancient Masterpieces of world Literature</b> from Harward University (Certificate of Achievement)</li>
        <li><b>Moral Foundation of Politics</b> from Yale University</li>
    </ul>
    <br/>
  </TimelineContent>
</StyledTimelineItem> */}
      </Timeline>
    </React.Fragment>
  );
}
