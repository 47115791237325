import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import Navbar from 'Layout/Navbar'
import PDFView from './PDFView'


export default function BasicGrid() {
  return (
    <Box sx={{ flexGrow: 1, background: 'white', minHeight: '100vh' }}>
      <Navbar />
      <Box sx={{
        overflowY: 'scroll',
        position: 'absolute',
        height: '100%',
        width:'-webkit-fill-available'
      }}>


        <Container sx={{ my: 5 }} maxWidth="md">

          <Grid container spacing={2} >
            <Grid item xs={12}>
              <Typography variant="h2" gutterBottom>Résumé</Typography>
              <Divider />
              <PDFView/>

              <Divider sx={{ py: 2 }} />
            </Grid>
           
          </Grid>
        </Container>
      </Box>
    </Box>
  );
}
