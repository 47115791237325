import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";

import img01 from 'assets/gallery/0/img1.JPG'
import img02 from 'assets/gallery/0/img2.JPG'
import img03 from 'assets/gallery/0/img3.JPG'
import img04 from 'assets/gallery/0/img4.JPG'
import img05 from 'assets/gallery/0/img5.JPG'
import img06 from 'assets/gallery/0/img6.JPG'
import img07 from 'assets/gallery/0/img7.JPG'

import img11 from 'assets/gallery/1/img1.JPG'
import img12 from 'assets/gallery/1/img2.JPG'
import img13 from 'assets/gallery/1/img3.JPG'
import img14 from 'assets/gallery/1/img4.JPG'
import img15 from 'assets/gallery/1/img5.JPG'
import img16 from 'assets/gallery/1/img6.JPG'
import img17 from 'assets/gallery/1/img7.JPG'

import img21 from 'assets/gallery/2/img1.JPG'
import img22 from 'assets/gallery/2/img2.JPG'
import img23 from 'assets/gallery/2/img3.JPG'
import img24 from 'assets/gallery/2/img4.JPG'
import img25 from 'assets/gallery/2/img5.JPG'
import img26 from 'assets/gallery/2/img6.JPG'
import img27 from 'assets/gallery/2/img7.JPG'

import img31 from 'assets/gallery/3/img1.JPG'
import img32 from 'assets/gallery/3/img2.JPG'
import img33 from 'assets/gallery/3/img3.JPG'
import img34 from 'assets/gallery/3/img4.JPG'
import img35 from 'assets/gallery/3/img5.JPG'
import img36 from 'assets/gallery/3/img6.JPG'
import img37 from 'assets/gallery/3/img7.JPG'
import img38 from 'assets/gallery/3/img8.JPG'
import img39 from 'assets/gallery/3/img9.JPG'

import img41 from 'assets/gallery/4/img1.jpg'
import img42 from 'assets/gallery/4/img2.jpg'
import img43 from 'assets/gallery/4/img3.JPG'
import img44 from 'assets/gallery/4/img4.jpg'

const images = [
  {
    original: img01,
    thumbnail: img01,
  },
  {
    original: img02,
    thumbnail: img02,
  },
  {
    original: img03,
    thumbnail: img03,
  },
  {
    original: img04,
    thumbnail: img04,
  },
  {
    original: img05,
    thumbnail: img05,
  },
  {
    original: img06,
    thumbnail: img06,
  },
  {
    original: img07,
    thumbnail: img07,
  },
  
  {
    original: img11,
    thumbnail: img11,
  },
  {
    original: img12,
    thumbnail: img12,
  },
  {
    original: img13,
    thumbnail: img13,
  },
  {
    original: img14,
    thumbnail: img14,
  },
  {
    original: img15,
    thumbnail: img15,
  },
  {
    original: img16,
    thumbnail: img16,
  },
  {
    original: img17,
    thumbnail: img17,
  },
  {
    original: img21,
    thumbnail: img21,
  },
  {
    original: img22,
    thumbnail: img22,
  },
  {
    original: img23,
    thumbnail: img23,
  },
  {
    original: img24,
    thumbnail: img24,
  },
  {
    original: img25,
    thumbnail: img25,
  },
  {
    original: img26,
    thumbnail: img26,
  },
  {
    original: img27,
    thumbnail: img27,
  },

  
  {
    original: img31,
    thumbnail: img31,
  },
  {
    original: img32,
    thumbnail: img32,
  },
  {
    original: img33,
    thumbnail: img33,
  },
  {
    original: img34,
    thumbnail: img34,
  },
  {
    original: img35,
    thumbnail: img35,
  },
  {
    original: img36,
    thumbnail: img36,
  },
  {
    original: img37,
    thumbnail: img37,
  },
  {
    original: img38,
    thumbnail: img38,
  },
  {
    original: img39,
    thumbnail: img39,
  },
  {
    original: img41,
    thumbnail: img41,
  },
  {
    original: img42,
    thumbnail: img42,
  },
  {
    original: img43,
    thumbnail: img43,
  },
  {
    original: img44,
    thumbnail: img44,
  },

];

export default function MyGallery () { 
    return <ImageGallery items={images} />;

}