import * as React from 'react';
import Box from '@mui/material/Box';
// import ImageList from '@mui/material/ImageList';
// import ImageListItem from '@mui/material/ImageListItem';

// import img1 from 'assets/historian/research/img1.jpeg';
// import img2 from 'assets/historian/research/img2.jpeg';
import img3 from 'assets/historian/research/img3.jpeg';
// import img4 from 'assets/historian/research/img4.jpeg';
// import img5 from 'assets/historian/research/img5.jpeg';

export default function StandardImageList() {
  return (
    // <ImageList cols={3} rowHeight={164} gap={0}>
    //   {itemData.map((item) => (
    //     <ImageListItem key={item.img}>
    //       <img
    //         src={item.url}
    //         alt={item.title}
    //         loading="lazy"
    //       />
    //     </ImageListItem>
    //   ))}
    // </ImageList>
    <Box sx={{display:'flex',alignItems:'center',justifyContent:'center'}}>
    <img src={img3} height="550" width="auto"/>

    </Box>

  );
}

const itemData = [
  // {
  //   url:img1,
  //   title: 'Breakfast',
  // },
  // {
  //   url:img2,
  //   title: 'Burger',
  // },
  // {
  //   url:img3,
  //   title: 'Camera',
  // },
  // {
  //   url:img4,
  //   title: 'Coffee',
  // },
  // {
  //   url:img5,
  //   title: 'Hats',
  // },
];
