import * as React from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import InboxIcon from '@mui/icons-material/Inbox';
import DraftsIcon from '@mui/icons-material/Drafts';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
export default function BasicList() {
  return (
    <Box sx={{ width: '100%' }}>

      <nav aria-label="main mailbox folders">
        <List>
          <ListItem >
              <ListItemIcon>
                <AccountCircleIcon />
              </ListItemIcon>
              <ListItemText primary="Name"  secondary="Yuvaan M. Bery"  />
          </ListItem>
          <ListItem >
              <ListItemIcon>
                <FmdGoodIcon />
              </ListItemIcon>
              <ListItemText primary="Address" secondary="14 Birbal Road, Jangpura, New Delhi, 110014, Delhi, India" />
          </ListItem>
          <ListItem >
              <ListItemIcon>
                <AlternateEmailIcon />
              </ListItemIcon>
              <ListItemText primary="Email" secondary="yuvaanbery@gmail.com" />
          </ListItem>
          <ListItem >
              <ListItemIcon>
                <PhoneAndroidIcon />
              </ListItemIcon>
              <ListItemText primary="Phone" secondary="+91 8527705542" />
          </ListItem>
        </List>
      </nav>     
    </Box>
  );
}
