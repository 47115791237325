import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';

import Navbar from 'Layout/Navbar'
import Bibliophilia from './Bibliophilia'
import Melophilia from './Melophilia'
import Aesthete from './Aesthete'
import Essay from './Essay'
import RevisionGuide from './RevisionGuide'
import Research from './Research'
import ResearchPDF from './ResearchPDF'
import Design from './Design'
import Bhashaflix from './Bhashaflix'
import Subscribe from './Subscribe'
import OutofBox from './OutofBox'

import compass from 'assets/compass.jpg'


export default function BasicGrid() {
  
  const isSmallDevice = useMediaQuery('(max-width:1023px)');
  return (
    <Box sx={{ flexGrow: 1, background: 'white', minHeight: '100vh' }}>
      <Navbar />
      <Box sx={{
        overflowY: 'scroll',
        position: 'absolute',
        height: '100%',
        width: '-webkit-fill-available'
      }}
        data-aos="fade-up">

        <Container
          sx={{
            backgroundImage: `url(${compass})`,
            minHeight: '60vh',
            backgroundSize: 'cover',
            display: 'flex',
            alignItems: 'center',
            color: 'white',


          }} maxWidth>
          <Grid container
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={2} sx={{ height: '100%' }}>
            <Grid item xs={12} sx={{ textAlign: 'center' }}>
              <Typography variant="h2" gutterBottom sx={{ fontFamily: 'AbrilFatface' }}>Historian's Corner</Typography>
              <Typography variant="subtitle" gutterBottom >Not all those wander are lost </Typography><br />

              <Typography variant="subtitle" gutterBottom >- J.R.R Tolkien </Typography>
              {/* <Divider /> */}
            </Grid>
          </Grid>
        </Container>


        <Container sx={{ my: 5 }} maxWidth="md">

          <Grid container spacing={10} >

            <Grid item xs={12}>
              <Typography variant="h4" gutterBottom sx={{ fontFamily: 'AbrilFatface' }} >About Us</Typography>

              <Divider />
              <Typography variant="body1">
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp;
                The past has always intrigued me. Something about the way in which humankind struggles to decipher and deconstruct it has always enthralled me. The earliest memory I have of the manifestation of this intellectual curiosity is in the stories my Badi Dadi (maternal great grandmother) used to tell my sister and I after our Sunday family lunches. These were stories about ancient mythology, the World Wars and the Independence Movement in India, and many more. The common thread in these tales was history – they all revolved around historical figures, settings, and events. Even though I probably did not realise it then, these stories of the past have had a lasting impact on my passion for the subject, keeping me continuously engaged with history. Early evidence of this engagement through bold and imaginative thinking can be seen in my coursework for IGCSE Art & Design, where I drew inspiration for my work from and made surprising connections with a source of history previously unexplored– the history of my own family. Through various mediums, photographs, documents, antiques and heirlooms I sought to record the story of my family, over several generations. This opened my eyes to how history is, in essence, all around us. Soon, I developed a keen appreciation for history, realising its vital importance in all our lives. It became one of my favourite subjects.
                <br />
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp;
                History is, in the simplest sense, a story: a combined narrative of all of humanity and beyond. As history enthusiasts and historians, it is our duty to pick up the loose threads and connect them to this story, much like a detective looking for clues to piece together a mystery. At the start of IB 1, my historical focus was primarily Eurocentric. As I was gearing up to learn about Imperial Russia, the French Revolution and the War of the Roses, our teacher informed us that we would be selecting Asia as our HL region. While, in all honesty, I was slightly disappointed initially, this decision has been hugely beneficial to my journey with the subject and I am extremely grateful for it – it made me realise that in order to a gain a truly ‘global’ understanding of history, other regions must be studied as well. My learnings from Rwanda to Idi Amin, from Nehru to the First World War have been nothing short of fascinating, but more importantly, I have gained a deeper respect for the ‘universal’ nature of history. Discussing my IBDP journey would be incomplete without a mention of historiography and the quest for truth, objectivity and balance in the subject. The multitude of perspectives on each topic discussed made me value the diversity in history; it is truly one of the most challenging fields of study because of the need to weigh various historical arguments and points of view before reaching any conclusion, making it all the more riveting. The value of history today is that it is the unsung hero of invention and discovery. I have learned to treasure and develop my love for the subject over these past few years and am constantly seeking to challenge my beliefs regarding history through research and discussion. And hence this website, the ‘Historian’s Corner’, a chance for fellow history enthusiasts to push their boundaries through diverse readings and a chance for me to share a few of the many things this subject has taught me.

              </Typography>
            </Grid>



            <Grid item container spacing={4} sx={{ my: 5 }}>

              <Grid item xs={12}>
                <Typography variant="h4" gutterBottom sx={{ fontFamily: 'AbrilFatface' }} >Blogs</Typography>

                <Divider />
              </Grid>
              <Grid item xs={12} lg={4}>
                <Aesthete />
              </Grid>
              <Grid item xs={12} lg={4}>
                <Bibliophilia />
              </Grid>
              <Grid item xs={12} lg={4}>
                <Melophilia />
              </Grid>
            </Grid>


            <Grid item xs={12}>
              <Typography variant="h4" gutterBottom sx={{ fontFamily: 'AbrilFatface' }} >Essay Competitions</Typography>
              <Divider />

              <Essay />
              <br />
              <br />
              <br />



            </Grid>


            <Grid item xs={12}>
              <RevisionGuide />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h4" gutterBottom sx={{ fontFamily: 'AbrilFatface' }} >Research Paper</Typography>
              <Divider />
              <Research />
              <br/><br/>
              <ResearchPDF/>
            </Grid>


            <Grid item xs={12}>
              <Typography variant={isSmallDevice ? 'h5':'h4'} gutterBottom sx={{ fontFamily: 'AbrilFatface' }} >IGCSE Art & Design Portfolio </Typography>
              <Divider />
              <Design />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h4" gutterBottom sx={{ fontFamily: 'AbrilFatface' }} >Bhashaflix</Typography>
              <Divider />
              <Bhashaflix />

            </Grid>






            <Grid item xs={12}>
              <Typography variant="h4" gutterBottom sx={{ fontFamily: 'AbrilFatface' }} >‘Out of the Box’ Interviews</Typography>
              <Divider />

              <OutofBox/>

              

            </Grid>

            <Grid item xs={12}>
              <Typography variant="body1">
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp;

                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp;

              </Typography>
            </Grid>



            <br />
            <br />
            <br />
            <br />
          </Grid>

        </Container>
        <Subscribe />
      </Box>
    </Box>
  );
}
