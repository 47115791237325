import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import bhasha from 'assets/historian/Devangiri.jpg'
import { Typography } from '@mui/material';
import { motion } from "framer-motion";
import IconButton from '@mui/material/IconButton';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

export default function SimpleContainer() {
    return (
        <React.Fragment>
            <CssBaseline />
            <Container maxWidth
                sx={{
                    backgroundImage: `url(${bhasha})`,
                    minHeight: '40vh',
                    backgroundSize: 'cover',
                    display: 'flex',
                    alignItems: 'center',
                    color: 'white',
                    justifyContent: 'center',
                  
                }}
            >
                <Typography variant="h5" sx={{ cursor: 'pointer', color: 'white',background:'black',p:2 }}
                    whileHover={{ scale: 1.2 }}
                    onClick={() => { window.open(`https://bhashaflix.web.app/`) }}
                    component={motion.h5}>
                    <IconButton sx={{ color: 'white' }}><OpenInNewIcon /></IconButton>
                    Bhashaflix
                </Typography>
            </Container>
        </React.Fragment>
    );
}
