import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import Navbar from 'Layout/Navbar'
import AwardTimeLine from './AwardTimeLine.js'
import Gallery from './Gallery'

import piano1 from 'assets/video/piano1.MOV'
import piano2 from 'assets/video/piano2.mp4'
import piano3 from 'assets/video/piano3.mp4'


export default function BasicGrid() {
  return (
    <Box sx={{ flexGrow: 1, background: 'white', minHeight: '100vh' }}>
      <Navbar />
      <Box sx={{
        overflowY: 'scroll',
        position: 'absolute',
        height: '100%',
        width: '-webkit-fill-available'
      }}>


        <Container sx={{ my: 5 }} maxWidth="md">

          <Grid container spacing={2} data-aos="fade-up" >
            <Grid item xs={12}>
              <Typography variant="h3" gutterBottom >Awards & Recognitions</Typography>
              <Divider />
              <AwardTimeLine />
            </Grid>

       

            <Grid item xs={12}>
              <Typography variant="h5" gutterBottom >Model  United  Nations  (Since 2019)</Typography>
              <Typography variant="body1">
                <ul>
                  <li>Core member, mentor and active participant of the MUN Club in my school</li>
                  <li>Participated in over a dozen MUNs both internationally and nationally</li>
                  <li>Chaired 3 committees as a member of the Executive Board (EB)</li>
                  <li>Won the <b>Best Delegate Award</b> in 6 MUNs</li>
                  <li>Received the <b>Outstanding Delegate</b> Award, as well as <b>Special and Honourable Mentions</b></li>
                </ul>
              </Typography>
              <Divider sx={{ py: 2 }} />
            </Grid>



            <Grid item container xs={12} spacing={2}>
              <Typography variant="h5" gutterBottom >Classical Piano  (Since 2012)</Typography>
              <Typography variant="body1">
                <ul>
                  <li>Over 10 years of training in classical piano</li>
                  <li><b>Distinction</b> in Grades Initial – 7 in practical examinations, with a <b>100%</b> in Grade 6 and Grade Initial</li>
                  <li> <b>Distinction</b> in Grades 1 – 5 in theory examinations, with a <b>100%</b> in Grade 1</li>
                  <li>Participated in the Trinity Laban Masterclass on the Art of Improvisation, conducted by Professor Douglas Finch (April – June 2021)</li>
                </ul>
              </Typography>
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom >Performances</Typography>
              </Grid>

              <Grid item xs={12} lg={4}>
                <video width="100%" height="auto" controls>
                  <source src={piano1} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
              </Grid>
              <Grid item xs={12} lg={4}>
                <video width="100%" height="auto" controls>
                  <source src={piano2} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
              </Grid>
              <Grid item xs={12} lg={4}>
                <video width="100%" height="auto" controls>
                  <source src={piano3} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
              </Grid>

              <Divider sx={{ py: 2 }} />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h5">My Gallery</Typography>
              <Divider /><br/>
              <Typography >
                This section encapsulates my tryst with art: an embodiment of my journey as a young artist seeking to make sense of the world around him through different mediums and subjects
                </Typography>
              <br /><br />
              <Gallery />
              <br /><br />
            </Grid>






          </Grid>
        </Container>
      </Box>
    </Box>
  );
}
