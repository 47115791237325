import React, { useState } from 'react';
import { Document, Page } from 'react-pdf';
import test from 'assets/resume.pdf'
import Box from '@mui/material/Box';
import { pdfjs } from 'react-pdf';
import { styled } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import Pagination from '@mui/material/Pagination';


// Import the main component
import { Viewer } from '@react-pdf-viewer/core';

// Import the styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import { Worker } from '@react-pdf-viewer/core';




pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const StyledBox = styled(Box, { shouldForwardProp: (prop) => prop !== 'issmalldevice' })(({ theme, issmalldevice }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    boxShadow: '0 .5rem 1rem rgba(0,0,0,.15)',
    position: 'relative',
    bottom: '0',
    transitionDuration: '0.3s',
    transitionProperty: 'all',
    '& .react-pdf__Page__canvas': {
        minWidth: '100%',
        minHeight: '80vh',
        width: '100%!important',
        height: 'auto!important',
        '& svg': {
            width: '100%',
            height: 'auto'
        }
    },
    '&:hover': {
        boxShadow: '0 1rem 3rem rgba(0,0,0,.175)',
        bottom: '5px',

    }
}));

export default function MyApp() {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }
    const handleChange = (event, value) => {
        setPageNumber(value);
    };
    return (
        <React.Fragment>
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.15.349/build/pdf.worker.min.js">
                <Viewer fileUrl={test} />
            </Worker>


            {/* <StyledBox>
        
            <Document
                file={test}
                onLoadSuccess={onDocumentLoadSuccess}
                renderMode="canvas"
                loading={<CircularProgress />}
            >
                <Page pageNumber={pageNumber} />
            </Document>   
        {numPages>1&&<Pagination count={numPages} page={pageNumber} onChange={handleChange} />}
        <br/><br/>
           

        </StyledBox> */}
        </React.Fragment>
    );
}