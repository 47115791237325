import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { motion } from "framer-motion";
import CardMedia from '@mui/material/CardMedia';
import aesthetic from 'assets/aesthetic.jpg';

import { itemBox } from 'Animation'
import { StyledCard, StyledContent } from './StyledCard'




export default function OutlinedCard() {

    return (
        <Box>
            <StyledCard>
                <CardMedia
                    component="img"
                    height="140"
                    image={aesthetic}
                    alt="aesthetic"
                />
                <StyledContent>
                    <Typography sx={{ fontSize: 20 , fontFamily: 'AbrilFatface' }} color="text.secondary" gutterBottom variants={itemBox} component={motion.div}>
                        Bibliophilia
                    </Typography>

                    <Button size="small"
                        sx={{ letterSpacing: '2px' }}
                        whileHover={{ scale: 1.2 }}
                        variants={itemBox}
                        onClick={()=>{window.open('https://yuvaanblogcom.wordpress.com/2020/05/17/bibliophilia/')}}
                        component={motion.button}>Bibliophilia</Button>
                </StyledContent>
            </StyledCard>
        </Box>
    );
}
