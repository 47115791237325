import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { Outlet } from "react-router-dom";
import Stack from '@mui/material/Stack';
import { matchRoutes, useLocation } from "react-router-dom"
import { useAnimation, motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

import useMediaQuery from '@mui/material/useMediaQuery';
import compass from 'assets/compass.jpg'
import cathedral from 'assets/cathedral.jpg'

import Info from './Info'
import Walk from './Walk'



const Item = styled(Paper)(({ theme }) => ({
    // backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

export default function BasicGrid() {
    const location = useLocation()
    const [ref, inView] = useInView();
    const isSmallDevice = useMediaQuery('(max-width:1023px)');

    const container = {
        hidden: (isSmallDevice) => ({
            opacity: 1,
            transform: isSmallDevice == 'true' ? 'rotate(0deg)' : 'rotate(35deg)',
            transition: {
                type: "spring",
                stiffness: 260,
                damping: 20,
                delay: .1
            }
        }),
        visible: (isSmallDevice) => ({
            opacity: 1, transform: 'rotate(0deg)',
            transition: {
                type: "spring",
                stiffness: 260,
                damping: 20,
                delay: .1
            }
        })
    };
    const containerControl = useAnimation();
    const [isHome, setIsHome] = React.useState(false)
    const [isHistorian, setIsHistorian] = React.useState(false)
    React.useEffect(() => {
        console.log(location)
        let pathname = location.pathname;
        if (pathname === '/') {
            setIsHome(true)
        } else {
            setIsHome(false)
        }
        if (pathname === '/historians-corner') {
            setIsHistorian(true)
        } else {
            setIsHistorian(false)
        }

    }, [location])

    React.useEffect(() => {
        if (isHome) {
            containerControl.start("hidden");
        } else {
            containerControl.start("visible");
        }

    }, [isHome, containerControl, inView])


    return (
        <Box sx={{
            flexGrow: 1,
            height: { xs: '100%', lg: '100vh' },
            backgroundImage: `url(${location.pathname === "/historians-corner" ? compass : cathedral})`,
            backgroundSize: 'cover',
            position: { xs: 'relative', lg: !isHome ? 'fixed' : 'relative' },
            overflow: !isHome ? 'hidden' : 'hidden',
            width: '100%',
        }}>
            <Box sx={{ backgroundColor: 'rgba(0,0,0,0.5)', position: { xs: 'fixed', lg: 'fixed' }, height: '100%', width: '100%' }}> </Box>
            <Grid container
                spacing={2}
                sx={{
                    height: { xs: '100%', md: '100vh' },
                    transform: !isHome || isSmallDevice ? 'rotate(0deg)' : 'rotate(-35deg)'
                }}

            >

                {
                    !isHistorian &&

                    <Grid item xs={12} md={5}
                        sx={{
                            height: !isHome ? 'auto' : '100%', border: '1px solid rgba(255, 255, 255, 0.2)',
                            transform: !isHome || isSmallDevice ? 'translateY(0vh)' : 'translateY(-28vh)',
                            display: !isHome && isSmallDevice ? 'none' : 'block',

                        }}
                    >
                        <Stack sx={{ height: '100%', }}
                            custom={isSmallDevice.toString()}
                            variants={container}
                            initial="hidden"
                            animate={containerControl}
                            ref={ref}
                            component={motion.div}
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                            spacing={2}>
                            {location.pathname === "/walk-the-talk-initiatives" && !isSmallDevice ? <Walk /> : <Info />}

                        </Stack>
                    </Grid>
                }
                <Grid item xs={12} md={isHistorian ? 12 :7} sx={{
                    background: !isHome && isSmallDevice ? 'white' : 'transparent', pl: { xs: 'auto', md: '0px!important' },

                }}>
                    <Outlet />
                </Grid>

            </Grid>
        </Box>
    );
}
