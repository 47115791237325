import {
  Routes,
  Route,
  BrowserRouter,
} from "react-router-dom";

import Home from 'Pages/Home'
import Resume from 'Pages/Resume'
import AboutMe from 'Pages/AboutMe'
import Historian from 'Pages/Historian'
import Awards from 'Pages/Awards'
import Walk from 'Pages/Walk'
import Layout from 'Layout'
import { ThemeProvider } from '@mui/material/styles'
import classicLight from 'theme/classicLight'
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles

export default function App() {
  // ..
AOS.init();
  return (
    <ThemeProvider theme={classicLight}>
    <BrowserRouter>
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="resume" element={<Resume />} />
        <Route path="about-me" element={<AboutMe />} />
        <Route path="historians-corner" element={<Historian />} />
        <Route path="awards" element={<Awards />} />
        <Route path="walk-the-talk-initiatives" element={<Walk />} />
      </Route>
    </Routes>
  </BrowserRouter>
  </ThemeProvider>
  );
}