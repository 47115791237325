import React, { useState } from 'react';
import { Document, Page } from 'react-pdf';
import test from 'assets/walk/pdf/Kavita.pdf'
import Box from '@mui/material/Box';
import { pdfjs } from 'react-pdf';
import { styled } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import Pagination from '@mui/material/Pagination';
import useMediaQuery from '@mui/material/useMediaQuery';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const StyledBox = styled(Box, { shouldForwardProp: (prop) => prop !== 'issmalldevice' })(({ theme, issmalldevice }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    '& .react-pdf__Page__canvas': {
        minWidth:'100%',
        minHeight:issmalldevice ? '0vh' :'80vh',
        width: '100%!important',
        height: 'auto!important',
        '& canvas': {
            width: '100%',
            height: 'auto',
            color:'black'
        }
    },
}));

export default function MyApp() {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const isSmallDevice = useMediaQuery('(max-width:1023px)');

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }
    const handleChange = (event, value) => {
        setPageNumber(value);
    };
    return (
        <StyledBox  issmalldevice={isSmallDevice.toString()}>
        
            <Document
                file={test}
                onLoadSuccess={onDocumentLoadSuccess}
                renderMode="canvas"
                loading={<CircularProgress />}
            >
                <Page pageNumber={pageNumber} />
            </Document>   
            {numPages>1&&<Pagination count={numPages} page={pageNumber} onChange={handleChange} />}

        </StyledBox>
    );
}