import * as React from 'react';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';

import pic1 from 'assets/walk/slides/1.jpeg'
import pic2 from 'assets/walk/slides/2.jpeg'
import pic3 from 'assets/walk/slides/3.jpeg'
import pic4 from 'assets/walk/slides/4.jpeg'
import pic5 from 'assets/walk/slides/5.jpeg'
import pic6 from 'assets/walk/slides/6.jpeg'
import pic7 from 'assets/walk/slides/7.jpeg'
import pic8 from 'assets/walk/slides/8.jpeg'
import pic9 from 'assets/walk/slides/9.jpeg'
import pic10 from 'assets/walk/slides/10.jpeg'
import pic11 from 'assets/walk/slides/11.jpeg'
import pic12 from 'assets/walk/slides/12.jpeg'
import pic13 from 'assets/walk/slides/13.jpeg'
import pic14 from 'assets/walk/slides/14.jpeg'
import pic15 from 'assets/walk/slides/15.jpeg'
import pic16 from 'assets/walk/slides/16.jpeg'
import pic17 from 'assets/walk/slides/17.jpeg'
import pic18 from 'assets/walk/slides/18.jpeg'
import pic19 from 'assets/walk/slides/19.jpeg'
import pic20 from 'assets/walk/slides/20.jpeg'
import pic21 from 'assets/walk/slides/21.jpeg'
import pic22 from 'assets/walk/slides/22.jpeg'
import pic23 from 'assets/walk/slides/23.jpeg'
import pic24 from 'assets/walk/slides/24.jpeg'
import pic25 from 'assets/walk/slides/25.jpeg'
import pic26 from 'assets/walk/slides/26.jpeg'
import pic27 from 'assets/walk/slides/27.jpeg'
import pic28 from 'assets/walk/slides/28.jpeg'
import pic29 from 'assets/walk/slides/29.jpeg'

import pic30 from 'assets/walk/slides/30.jpeg'
import pic31 from 'assets/walk/slides/31.jpeg'
import pic32 from 'assets/walk/slides/32.jpeg'
import pic33 from 'assets/walk/slides/33.jpeg'
import pic34 from 'assets/walk/slides/34.jpeg'
import pic35 from 'assets/walk/slides/35.jpeg'
import pic36 from 'assets/walk/slides/36.jpeg'
import pic37 from 'assets/walk/slides/37.jpeg'
import pic38 from 'assets/walk/slides/38.jpeg'
import pic39 from 'assets/walk/slides/39.jpeg'

import pic40 from 'assets/walk/slides/40.jpeg'
import pic41 from 'assets/walk/slides/41.jpeg'
import pic42 from 'assets/walk/slides/42.jpeg'
import pic43 from 'assets/walk/slides/43.jpeg'
import pic44 from 'assets/walk/slides/44.jpeg'
import pic45 from 'assets/walk/slides/45.jpeg'
import pic46 from 'assets/walk/slides/46.jpeg'
import pic47 from 'assets/walk/slides/47.jpeg'
import pic48 from 'assets/walk/slides/48.jpeg'
import pic49 from 'assets/walk/slides/49.jpeg'


import pic50 from 'assets/walk/slides/50.jpeg'
import pic51 from 'assets/walk/slides/51.jpeg'
import pic52 from 'assets/walk/slides/52.jpeg'
import pic53 from 'assets/walk/slides/53.jpeg'
import pic54 from 'assets/walk/slides/54.jpeg'
import pic55 from 'assets/walk/slides/55.jpeg'
import pic56 from 'assets/walk/slides/56.jpeg'
import pic57 from 'assets/walk/slides/57.jpeg'
import pic58 from 'assets/walk/slides/58.jpeg'
import pic59 from 'assets/walk/slides/59.jpeg'


import pic60 from 'assets/walk/slides/60.jpeg'
import pic61 from 'assets/walk/slides/61.jpeg'
import pic62 from 'assets/walk/slides/62.jpeg'
import pic63 from 'assets/walk/slides/63.jpeg'
import pic64 from 'assets/walk/slides/64.jpeg'
import pic65 from 'assets/walk/slides/65.jpeg'
import pic66 from 'assets/walk/slides/66.jpeg'
import pic67 from 'assets/walk/slides/67.jpeg'
import pic68 from 'assets/walk/slides/68.jpeg'
import pic69 from 'assets/walk/slides/69.jpeg'


import pic70 from 'assets/walk/slides/70.jpeg'
import pic71 from 'assets/walk/slides/71.jpeg'
import pic72 from 'assets/walk/slides/72.jpeg'
import pic73 from 'assets/walk/slides/73.jpeg'
import pic74 from 'assets/walk/slides/74.jpeg'
import pic75 from 'assets/walk/slides/75.jpeg'
import pic76 from 'assets/walk/slides/76.jpeg'


export default function MasonryImageList() {
  return (
    <Box 
    sx={{ 
        width: '100%',
         height: '100vh', 
        overflowY: 'scroll'
     }}
    >
      <ImageList variant="masonry" cols={3} gap={0}>
        {itemData.map((item) => (
          <ImageListItem key={item.img} sx={{border:'2px solid white'}} >
            <img
              src={`${item.img}?w=248&fit=crop&auto=format`}
              srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
              alt={item.title}
              loading="lazy"
            />
          </ImageListItem>
        ))}
      </ImageList>
    </Box>
  );
}

const itemData = [
    {
      img: pic1,
      title: 'Slides',
    },
    {
      img: pic2,
      title: 'Slides',
    },
    {
      img: pic3,
      title: 'Slides',
    },
    {
      img: pic5,
      title: 'Slides',
    },
    {
      img: pic7,
      title: 'Slides',
    },
    {
      img: pic8,
      title: 'Slides',
    },
    {
      img: pic9,
      title: 'Slides',
    },
    {
      img: pic10,
      title: 'Slides',
    },
    {
      img: pic11,
      title: 'Slides',
    },
    {
      img: pic12,
      title: 'Slides',
    },
    {
      img: pic13,
      title: 'Slides',
    },
    {
      img: pic14,
      title: 'Slides',
    },
    {
      img: pic15,
      title: 'Slides',
    },
    {
      img: pic16,
      title: 'Slides',
    },
    {
      img: pic17,
      title: 'Slides',
    },
    {
      img: pic18,
      title: 'Slides',
    },
    {
      img: pic19,
      title: 'Slides',
    },
    {
      img: pic20,
      title: 'Slides',
    },
    {
      img: pic21,
      title: 'Slides',
    },
    {
      img: pic22,
      title: 'Slides',
    },
    {
      img: pic23,
      title: 'Slides',
    },
    {
      img: pic24,
      title: 'Slides',
    },
    {
      img: pic25,
      title: 'Slides',
    },
    {
      img: pic26,
      title: 'Slides',
    },
    {
      img: pic27,
      title: 'Slides',
    },
    {
      img: pic28,
      title: 'Slides',
    },
    {
      img: pic29,
      title: 'Slides',
    },




    {
      img: pic30,
      title: 'Slides',
    },
    {
      img: pic31,
      title: 'Slides',
    },
    {
      img: pic32,
      title: 'Slides',
    },
    {
      img: pic33,
      title: 'Slides',
    },
    {
      img: pic34,
      title: 'Slides',
    },
    {
      img: pic35,
      title: 'Slides',
    },
    {
      img: pic36,
      title: 'Slides',
    },
    {
      img: pic37,
      title: 'Slides',
    },
    {
      img: pic38,
      title: 'Slides',
    },
    {
      img: pic39,
      title: 'Slides',
    },



    
    {
      img: pic40,
      title: 'Slides',
    },
    {
      img: pic41,
      title: 'Slides',
    },
    {
      img: pic42,
      title: 'Slides',
    },
    {
      img: pic43,
      title: 'Slides',
    },
    {
      img: pic44,
      title: 'Slides',
    },
    {
      img: pic45,
      title: 'Slides',
    },
    {
      img: pic46,
      title: 'Slides',
    },
    {
      img: pic47,
      title: 'Slides',
    },
    {
      img: pic48,
      title: 'Slides',
    },
    {
      img: pic49,
      title: 'Slides',
    },


    
    {
      img: pic50,
      title: 'Slides',
    },
    {
      img: pic51,
      title: 'Slides',
    },
    {
      img: pic52,
      title: 'Slides',
    },
    {
      img: pic53,
      title: 'Slides',
    },
    {
      img: pic54,
      title: 'Slides',
    },
    {
      img: pic55,
      title: 'Slides',
    },
    {
      img: pic56,
      title: 'Slides',
    },
    {
      img: pic57,
      title: 'Slides',
    },
    {
      img: pic58,
      title: 'Slides',
    },
    {
      img: pic59,
      title: 'Slides',
    },



    
    {
      img: pic60,
      title: 'Slides',
    },
    {
      img: pic61,
      title: 'Slides',
    },
    {
      img: pic62,
      title: 'Slides',
    },
    {
      img: pic63,
      title: 'Slides',
    },
    {
      img: pic64,
      title: 'Slides',
    },
    {
      img: pic65,
      title: 'Slides',
    },
    {
      img: pic66,
      title: 'Slides',
    },
    {
      img: pic67,
      title: 'Slides',
    },
    {
      img: pic68,
      title: 'Slides',
    },
    {
      img: pic69,
      title: 'Slides',
    },


    
    {
      img: pic70,
      title: 'Slides',
    },
    {
      img: pic71,
      title: 'Slides',
    },
    {
      img: pic72,
      title: 'Slides',
    },
    {
      img: pic73,
      title: 'Slides',
    },
    {
      img: pic74,
      title: 'Slides',
    },
    {
      img: pic75,
      title: 'Slides',
    },
    {
      img: pic76,
      title: 'Slides',
    },
 


];
