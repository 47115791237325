import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import IconButton from '@mui/material/IconButton';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';

import aesthetic from 'assets/historian/essay.jpg';
import history from 'assets/historian/history.pdf';
import butler from 'assets/historian/butler.pdf';
import immerse from 'assets/historian/immerse.pdf';
import newnormal from 'assets/historian/newnormal.pdf';
import germans from 'assets/historian/germans.pdf';
import useMediaQuery from '@mui/material/useMediaQuery';


export default function CustomImageList() {
  const matches = useMediaQuery('(max-width:600px)');


  return (
    <ImageList
      sx={{
        overflowY:'inherit'
      }}
      rowHeight={200}
      gap={0}
      cols={3}
    >
      {itemData.map((item) => {
        return (
          <ImageListItem key={item.img} cols={matches ? 3 : item.cols} rows={item.rows}>
            <img
            src={item.img}
              alt={item.title}
              loading="lazy"
            />
            <ImageListItemBar
              sx={{
                background:
                  'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
                  'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
                  cursor:'pointer'
              }}
              title={item.title}
              position="top"
              actionIcon={
                <IconButton
                  sx={{ color: 'white' }}
                  aria-label={`star ${item.title}`}
                >
                  <DownloadOutlinedIcon />
                </IconButton>
              }
              actionPosition="left"
              onClick={()=>{window.open(item.pdf)}}
            />
          </ImageListItem>
        );
      })}
    </ImageList>
  );
}

const itemData = [
  {
    img:aesthetic,
    title: 'John Locke Essay',
    author: '@bkristastucchio',
    featured: true,
    cols:1,
    rows:1,
    pdf:history
  },
  {
    img:aesthetic,
    title: 'R.A. Butler Politics Prize',
    author: '@rollelflex_graphy726',
    cols:1,
    rows:2,
    pdf:butler
  },
  {
    img:aesthetic,
    title: 'Immerse Essay',
    author: '@helloimnik',
    cols:1,
    rows:1,
    pdf:immerse
  },
  {
    img:aesthetic,
    title: 'The New Normal Essay',
    author: '@nolanissac',
    cols:1,
    rows:1,
    pdf:newnormal
  },
  {
    img:aesthetic,
    title: 'Robson History Prize',
    author: '@hjrc33',
    cols:1,
    rows:1,
    pdf:germans
  },
];
