import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom'
import useMediaQuery from '@mui/material/useMediaQuery';

const StyledGrid = styled(Grid, { shouldForwardProp: (prop) => prop !== 'issmalldevice' })(({ theme, issmalldevice }) => ({
    minHeight: issmalldevice? '0px' : '30vh', border: '1px solid rgba(255, 255, 255, 0.2)',
    transition: 'all 0.5s',
    fontFamily: 'Poppins!important',

    '& .textContainer': {
        height: '100%',
        color: 'white!important',
        fontFamily: 'Poppins!important',
        textAlign: issmalldevice? 'center' : 'left',
        // transform:isSmallDevice=='true' ? 'rotate(0deg)': 'rotate(35deg)',
        // color:isSmallDevice=='true' ? 'red' : 'blue',
        position: 'relative', bottom: '3rem',
        '& .MuiTypography-root': {
            transition: 'all 0.5s',
        },
        ...(issmalldevice === 'true' && {
            transform: 'rotate(0deg)',
            // color:'red'
        }),
        ...(issmalldevice !== 'true' && {
            transform: 'rotate(35deg)',
            // color:'blue'
        }),
    },
    '&:hover': {
        border: '15px solid rgba(255, 255, 255, 0.1)',
        '& .textContainer': {

            '& .MuiTypography-root': {
                scale: '1.2'
            }
        }
    }
}));

export default function BasicGrid() {
    const isSmallDevice = useMediaQuery('(max-width:1023px)');
    // console.log(isSmallDevice)
    return (
        <Box sx={{ flexGrow: 1, pl: 2 }}>
            <Grid container spacing={2} sx={{ height: { xs: '100%', md: '100vh' }, color: 'white!important' }}>
                <StyledGrid item xs={12} md={6} 
                    issmalldevice={isSmallDevice.toString()} >
                    <Link to="about-me" style={{ color: 'white!important', textDecoration: 'none' }}>
                        <Stack className='textContainer'
                            direction="column"
                            justifyContent="flex-end"
                            alignItems="center"
                            spacing={2}>
                            {/* <Typography variant="h6" data-aos="zoom-in">01</Typography> */}
                            <Typography variant="h4" data-aos="zoom-in" data-aos-delay="200" sx={{ fontWeight: 600, fontFamily: 'Poppins', }}  >ABOUT ME</Typography>

                        </Stack>
                    </Link>
                </StyledGrid>
                <Grid item xs={12} md={6} issmalldevice={isSmallDevice.toString()}
                    sx={{
                        minHeight: '30vh', border: '1px solid rgba(255, 255, 255, 0.2)',
                        transform: { xs: 'translateY(0)', md: 'translateY(12vh)' },
                        display: { xs: 'none', md: 'block' }
                    }}>
                    {/* <Stack sx={{ height: '100%' }}
                        direction="column"
                        justifyContent="flex-end"
                        alignItems="center"
                        spacing={2}>
                        <Item>Item 1</Item>
                        <Item>Item 2</Item>
                        <Item>Item 3</Item>
                    </Stack> */}
                </Grid>
                <StyledGrid item xs={12} md={6}
                    issmalldevice={isSmallDevice.toString()}>
                    <Link to="resume" style={{ color: 'white!important', textDecoration: 'none' }}>
                        <Stack className='textContainer'
                            direction="column"
                            justifyContent="flex-end"
                            alignItems="center"
                            spacing={2}>
                            {/* <Typography variant="h6" data-aos="zoom-in" >02</Typography> */}
                            <Typography variant="h4" data-aos="zoom-in" data-aos-delay="200" sx={{ fontWeight: 600, fontFamily: 'Poppins', textTransform: 'capitalize' }}>RÉSUMÉ</Typography>

                        </Stack>
                    </Link>
                </StyledGrid>
                <StyledGrid item xs={12} md={6}
                    issmalldevice={isSmallDevice.toString()}
                    sx={{transform: { xs: 'translateY(0)', md: 'translateY(12vh)' } }}>
                    <Link to="historians-corner" style={{ color: 'white!important', textDecoration: 'none' }}>
                        <Stack className='textContainer'
                            direction="column"
                            justifyContent="flex-end"
                            alignItems="center"
                            spacing={2}>
                            {/* <Typography variant="h6" data-aos="zoom-in" >04</Typography> */}
                            <Typography variant="h4" data-aos="zoom-in" data-aos-delay="200" sx={{ fontWeight: 600, fontFamily: 'Poppins', }}>HISTORIAN'S<br /> CORNER</Typography>

                        </Stack>
                    </Link>
                </StyledGrid>
                <StyledGrid item xs={12} md={6}
                    issmalldevice={isSmallDevice.toString()}>
                    <Link to="walk-the-talk-initiatives" style={{ color: 'white!important', textDecoration: 'none' }}>
                        <Stack className='textContainer'
                            direction="column"
                            justifyContent="flex-end"
                            alignItems="center"
                            spacing={2}>
                            {/* <Typography variant="h6" data-aos="zoom-in" >03</Typography> */}
                            <Typography variant="h4" data-aos="zoom-in" data-aos-delay="200" sx={{ fontWeight: 600, fontFamily: 'Poppins' }}>'WALK <br />THE TALK' <br />INITIATIVES</Typography>

                        </Stack>
                    </Link>
                </StyledGrid>
                <StyledGrid item xs={12} md={6}
                    issmalldevice={isSmallDevice.toString()}
                    sx={{transform: { xs: 'translateY(0)', md: 'translateY(12vh)' } }}>
                    <Link to="awards" style={{ color: 'white!important', textDecoration: 'none' }}>
                        <Stack className='textContainer'
                            direction="column"
                            justifyContent="flex-end"
                            alignItems="center"
                            spacing={2}>

                            {/* <Typography variant="h6" data-aos="zoom-in" >05</Typography> */}
                            <Typography variant="h4" data-aos="zoom-in" data-aos-delay="200" sx={{ fontWeight: 600, fontFamily: 'Poppins' }}>AWARDS <br />& <br />RECOGNITION</Typography>
                        </Stack>
                    </Link>
                </StyledGrid>
                
                {/* <Grid item xs={6} sx={{ minHeight: '30vh', border: '1px solid rgba(255, 255, 255, 0.2)' }}>
                    <Stack sx={{ height: '100%', transform: 'rotate(35deg)' }}
                        direction="column"
                        justifyContent="flex-end"
                        alignItems="center"
                        spacing={2}>
                  
                    </Stack>
                </Grid> */}
                {/* <Grid item xs={6} sx={{ minHeight: '30vh', transform: 'translateY(12vh)' }}>
                    <Stack sx={{ height: '100%' }}
                        direction="column"
                        justifyContent="flex-end"
                        alignItems="center"
                        spacing={2}>
                        <Item>Item 1</Item>
                        <Item>Item 2</Item>
                        <Item>Item 3</Item>
                    </Stack>
                </Grid> */}

            </Grid>
        </Box>
    );
}
