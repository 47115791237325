import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import { styled } from '@mui/material/styles';

import CardContent from '@mui/material/CardContent';
import { useInView } from "react-intersection-observer";
import { useAnimation, motion } from "framer-motion";

import { containerBox, itemBox } from 'Animation'

export const StyledCard = styled(Card)(({ theme }) => ({
    transition: 'all .3s',
    position: 'relative',
    bottom: 0,
    minHeight:445,    
    boxShadow: '0 .125rem .25rem rgba(0,0,0,.075)',
    '& .MuiCardMedia-root': {
        transition: 'all .8s',
    },
    '&:hover': {
        boxShadow: '0 .5rem 1rem rgba(0,0,0,.15)',
        bottom: 3,
        '& .MuiCardMedia-root': {
            width: '125%'
        }
    }
}));


// export function StyledCard({children}) {

//     return (
//         <Box>
//             <StyledCard variant="outlined">
//                 {children}             
//             </StyledCard>
//         </Box>
//     );
// }

export function StyledContent({ children }) {
    const containerBoxControl = useAnimation();
    const [ref, inView] = useInView();
    React.useEffect(() => {
        if (inView) {
            containerBoxControl.start("visible");
        } else {
            containerBoxControl.start("hidden");
        }
    }, [containerBoxControl, inView]);

    return (
        <CardContent
            variants={containerBox}
            initial="hidden"
            component={motion.div}
            animate={containerBoxControl}
            ref={ref}
        >
            {children}
        </CardContent>
    );
}

