import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

export default function SimpleContainer() {
  return (
    <React.Fragment>
      <Container maxWidth sx={{mb:'10rem'}}>
        <Grid container
          direction="column"
          justifyContent="center"
          alignItems="center" spacing={2} sx={{ minheight: '20vh' }}>
          <Grid item xs={12}>
            <Typography variant="h4">Subscribe</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle2">
              Keep updated by joining our mailing list
            </Typography>


          </Grid>
          
          <Grid item xs={12}>
          <TextField id="outlined-basic" label="Name"  sx={{minWidth:{xs:320,lg:400}}} fullWidth variant="outlined" />

          </Grid>
          <Grid item xs={12}>
          <TextField id="outlined-basic" label="Email" sx={{minWidth:{xs:320,lg:400}}}  fullWidth variant="outlined" />

          </Grid>
          <Grid item xs={12}>
            <Button variant="contained">Submit</Button>
          </Grid>

        </Grid>
      </Container>
    </React.Fragment>
  );
}
