import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import img1 from 'assets/historian/design/IMG-3913.jpg';
import img2 from 'assets/historian/design/IMG-3914.jpg';
import img3 from 'assets/historian/design/IMG-3915.jpg';
import img4 from 'assets/historian/design/IMG-3916.jpg';
import img5 from 'assets/historian/design/IMG-3917.jpg';
import img6 from 'assets/historian/design/IMG-3919.jpg';
import img7 from 'assets/historian/design/IMG-3921.jpg';
import img8 from 'assets/historian/design/IMG-3922.jpg';
import img9 from 'assets/historian/design/IMG-3923.jpg';
import img10 from 'assets/historian/design/IMG-3924.jpg';
import img11 from 'assets/historian/design/IMG-3925.jpg';
import img12 from 'assets/historian/design/IMG-3926.jpg';
import img13 from 'assets/historian/design/IMG-3927.jpg';

import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

export const StyledCard = styled(Card)(({ theme }) => ({
    transition: 'all .3s',
    position: 'relative',
    bottom: 0,
    marging: 2,
    width: '300px',
    height: 'auto',
    boxShadow: '0 .5rem 1rem rgba(0,0,0,.15)',
}));

export default function BasicGrid() {

    const options = {
        margin: 30,
        responsiveClass: true,
        nav: true,
        dots: true,
        autoplay: false,
        navText: ["Prev","Next"],
        smartSpeed: 1000,
        responsive: {
            0: {
                items: 1,
            },
            400: {
                items: 1,
            },
            600: {
                items: 2,
            },
            700: {
                items: 2,
            },
            1000: {
                items: 2,

            }
        },
    };


    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <OwlCarousel className='owl-theme'  {...options}>
                        <div class='item' style={{ margin: '20px' }}>
                            <StyledCard>
                                <CardMedia
                                    component="img"
                                    image={img1}
                                    alt="green iguana"
                                />
                            </StyledCard>
                        </div>
                        <div class='item' style={{ margin: '20px' }}>
                            <StyledCard>
                                <CardMedia
                                    component="img"
                                    image={img2}
                                    alt="green iguana"
                                />
                            </StyledCard>
                        </div>
                        <div class='item' style={{ margin: '20px' }}>
                            <StyledCard >
                                <CardMedia
                                    component="img"
                                    image={img3}
                                    alt="green iguana"
                                />
                            </StyledCard>
                        </div>
                        <div class='item' style={{ margin: '20px' }}>
                            <StyledCard >
                                <CardMedia
                                    component="img"
                                    image={img4}
                                    alt="green iguana"
                                />
                            </StyledCard>
                        </div>
                        <div class='item' style={{ margin: '20px' }}>
                            <StyledCard >
                                <CardMedia
                                    component="img"
                                    image={img5}
                                    alt="green iguana"
                                />
                            </StyledCard>
                        </div>
                        <div class='item' style={{ margin: '20px' }}>
                            <StyledCard >
                                <CardMedia
                                    component="img"
                                    image={img6}
                                    alt="green iguana"
                                />
                            </StyledCard>
                        </div>
                        <div class='item' style={{ margin: '20px' }}>
                            <StyledCard >
                                <CardMedia
                                    component="img"
                                    image={img7}
                                    alt="green iguana"
                                />
                            </StyledCard>
                        </div>
                        <div class='item' style={{ margin: '20px' }}>
                            <StyledCard >
                                <CardMedia
                                    component="img"
                                    image={img8}
                                    alt="green iguana"
                                />
                            </StyledCard>
                        </div>
                        <div class='item' style={{ margin: '20px' }}>
                            <StyledCard >
                                <CardMedia
                                    component="img"
                                    image={img9}
                                    alt="green iguana"
                                />
                            </StyledCard>
                        </div>
                        <div class='item' style={{ margin: '20px' }}>
                            <StyledCard >
                                <CardMedia
                                    component="img"
                                    image={img10}
                                    alt="green iguana"
                                />
                            </StyledCard>
                        </div>
                        <div class='item' style={{ margin: '20px' }}>
                            <StyledCard >
                                <CardMedia
                                    component="img"
                                    image={img11}
                                    alt="green iguana"
                                />
                            </StyledCard>
                        </div>
                        <div class='item' style={{ margin: '20px' }}>
                            <StyledCard >
                                <CardMedia
                                    component="img"
                                    image={img12}
                                    alt="green iguana"
                                />
                            </StyledCard>
                        </div>
                        <div class='item' style={{ margin: '20px' }}>
                            <StyledCard >
                                <CardMedia
                                    component="img"
                                    image={img13}
                                    alt="green iguana"
                                />
                            </StyledCard>
                        </div>
                    </OwlCarousel>
                </Grid>
            </Grid>
        </Box>
    );
}
